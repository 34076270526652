<template>
<section>
    <div class="row">
        <div class="col-12">
            <div class="card-box">

                <b-table :fields="fields" :items="elements" bordered striped responsive :select-head="false" class="tablesaw" @sort-changed="sortChange">

                    <template v-slot:cell(delete)="data">
                        <template v-if="statusAllowEdit || data.item.toCreate">
                            <b-form-checkbox v-model="selection" :value="data.item"></b-form-checkbox>
                        </template>
                    </template>
                    <template v-slot:cell()="data">

                        <template v-if="data.item.toUpdate">
                            <span>{{data.item[data.field.key]}}</span>
                        </template>
                        <template v-if="data.item.toCreate">
                            <span>{{data.item[data.field.key]}}</span>
                        </template>

                    </template>

                    <template v-slot:cell(itemCode)="row">
                        <item-code-picker :key="''+ paginate.current_page + row.index" v-if="statusAllowEdit || row.item.toCreate" :index="''+ paginate.current_page + row.index" :modelValue="{itemCode: row.item['itemCode']}" @itemCodeInput="changeItemCode('itemCode', $event, row)"></item-code-picker>
                        <div v-else>{{row.item['itemCode']}}</div>
                    </template>
                    <template v-slot:cell(expectAmount)="row">
                        <b-form-input @input="changeProperties('expectAmount', $event, row)" v-if="statusAllowEdit || row.item.toCreate" type="text" v-model="row.item['expectAmount']"></b-form-input>
                        <div v-else>{{row.item['expectAmount']}}</div>
                    </template>

                </b-table>

                <div class="d-flex justify-content-between px-2" v-if="statusAllowEdit">
                    <b-button v-on:click="addItem">追加</b-button>
                    <ButtonDelete @click="removeItems"></ButtonDelete>
                </div>

                <b-pagination style="padding-bottom:15px;padding-top:15px" align="center" :total-rows="paginate.total" :per-page="Number(paginate.per_page)" v-model="paginate.current_page" @page-click="updatePage" class="my-0" />

            </div>
        </div>
    </div>

</section>
</template>

<script>
import {
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BListGroup,
    BTable,
    BPagination,
    BButton
} from 'bootstrap-vue-next'
import ButtonDelete from "../../components/buttons/ButtonDelete.vue";
import ItemCodePicker from "../ItemCodePicker.vue";

export default {
    props: ['headers', 'elements', 'options', 'paginate', 'count', 'translate', 'data', 'work_number', 'form', 'errors', 'temporary_locations', 'csv', 'statusAllowEdit'],
    components: {
        ItemCodePicker,
        BTable,
        BInputGroup,
        BFormInput,
        BInputGroupText,
        BListGroup,
        BPagination,
        ButtonDelete,
        BButton
    },
    mounted() {
    },
    computed: {
        fields() {
            const deleteColumn = {
                key: 'delete',
                label: this.$t("select"),
                sortable: false
            };

            const headers = this.headers.map((item) => {
                const datas = item.source.split('.')
                var itemKey = ""
                if (datas.length == 2) {
                    itemKey = datas[1]
                } else if (datas.length == 3) {
                    itemKey = datas[2]
                }
                if (itemKey === "itemCode") {
                    return {
                        key: itemKey,
                        label: item.name,
                        id: item.order,
                        sortable: false,
                        thStyle: {
                            "min-width": '150px'
                        }
                    }
                } else {
                    return {
                        key: itemKey,
                        label: item.name,
                        id: item.order,
                        sortable: false,
                    }
                }
            }).sort(function (first, second) {
                return first['id'] - second['id']
            });
            return [deleteColumn, ...this.fixedFields, ...headers];
        },
    },
    data() {
        return {
            fixedFields: [{
                key: "expectAmount",
                label: this.$t('expected_number'),
                sortable: false,
                thStyle: {
                    "min-width": '100px'
                }
            }],
            selection: [],
        }
    },
    methods: {
        addItem() {
            this.$emit('addItem')
        },
        changeItemCode(target, value, row) {
            this.$emit('changeItemCode', value, row, target)
        },
        changeProperties(target, value, row) {
            this.$emit('changeProperties', value, row, target)
        },

        updatePage(BvEvent, page) {
            this.$emit('updatePage', page);
        },
        updateCsvPage(page) {
            this.$emit('updateCsvPage', page);
        },

        removeItems() {
            this.$emit('removeItems', this.selection)
            this.selection = []
        },
        sortChange(sort) {
            this.$emit('sortChange', sort)
        },
    },
    watch: {
        elements: {
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler() {}
        },
        paginate: {
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler() {}
        }
    }
}
</script>

<style scoped>
</style>
