<template>
<section>
    <b-modal :modelValue="modalCreateJob" :title="modalTitle" size="xxl" centered scrollable @hidden="cancelFun" @show="build" @close="hideModal">
        <template v-slot:footer="{ ok, cancel, hide }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button v-if="statusPlanned" style="margin-right: auto;" size="md" variant="danger" @click="deleteConfirm">
                削除
            </b-button>
            <b-button v-if="statusProgress" size="md" variant="primary" @click.prevent="showConfirm">
                作業完了
            </b-button>

            <b-button size="md" variant="primary" @click="send" :disabled="working">
                <template v-if="working">
                    <i class="fa fa-spin fa-hourglass m-r-5"></i>
                    処理中
                </template>
                <template v-else>確認</template>

            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button size="md" variant="outline-secondary" :disabled="working" @click="cancelFun">
                キャンセル
            </b-button>
        </template>
        <article class="container-fluid">
            <form>
                <div class="row" style="margin-bottom:10px">

                    <div class="form-row form_container" :key="dataChangeFlag">
                        <div class="form-group col-auto">
                            <label> 作業番号 </label>
                            <b-form-input class="form-control mb-4" v-model="work_number" disabled />
                        </div>
                        <modal-input v-for="item in work_properties" :key="item.name" :item="item" :modelValue="work_properties_value[item.name]" @updateValue="updateValue($event, item.name)"></modal-input>
                    </div>
                </div>
            </form>

            <table-create style="margin-bottom:30px" :title="title" :headers="headers" :elements="currentData" @addItem="addItem" @changeProperties="changeProperties" @changeItemCode="changeItemCode" :temporary_locations="temporary_locations" :translate="translate" :data="data" :form="form" :errors="errors" :paginate="paginate" :count="count" :csv="csv" :statusAllowEdit="statusPlanned" ref="table" @removeItems="removeItems" @sortChange="sortChange" @updatePage="updatePage">
            </table-create>

        </article>

    </b-modal>
    <b-modal :modelValue="msgModal" @hide.prevent hide-header @ok="msgModalOK" @cancel="msgModalCancel">
        {{msgModalTitle}}
    </b-modal>
</section>
</template>

<script>
import TableCreate from '../table/TableCreate.vue'
import TheSelect from '../TheSelect.vue'
import moment from 'moment';
import {
    BFormInput,
    BFormCheckbox
} from "bootstrap-vue-next";
import * as mutations from "../../graphql/locus_mutations";
import {
    API
} from "aws-amplify";
import AwsApi from "../../Api"
import * as queries from "../../graphql/locus_queries";
import ModalInput from './ModalInput.vue'

export default {
    filters: {
        moment: function (date) {
            return date ? moment(date).format('YYYY/MM/DD') : '';
        }
    },
    emits: ["refetchData", "hideModalCreateJob", "changeMsgModal"],
    props: ['msgModal', 'modalCreateJob', 'url', 'tenantId', 'infos', 'form_select_options', 'translate', 'type', 'data', 'json', 'pushToItemsOptions', 'function_id', 'menu_id', 'title', 'items_from_page', 'csv', 'work_properties'],
    components: {
        'table-create': TableCreate,
        'app-select': TheSelect,
        BFormInput,
        BFormCheckbox,
        ModalInput
    },
    data() {
        return {
            base_item: {
                unique_id: "",
                'itemCode': "",
                'expectAmount': "",
                toCreate: true
            },
            paginate: {
                total: 0,
                current_page: 1,
                per_page: 30
            },
            headers: [],
            limit: 1000,
            offset: 0,
            page: 1,
            count: 30,
            work_number: "",
            work_properties_value: {},
            work_data_changed_count: -1,
            allData: [],
            currentData: [],
            allData_toDelete: [],

            workDetailsEmpty: null,

            initial_form_items: [],
            form: {},
            sortBy: [],
            initialForm: {
                // scheduled_date: null,
                // work_classification: null,
                // business_partner: null,
                // warehouse_id: this.$route.params.id,
            },

            work_classifications: [],
            // items_all: new Array(1000).fill('hello'),
            temporary_locations: [],
            errors: [],
            table_items: [],
            bulk_save: false,

            // csv: false,
            labels: [],
            working: false,

            pending_new: [],
            pending_change: {},
            cache: {},
            msgModalTitle: "",
            dataChangeFlag: 0
        }
    },
    watch: {
        'infos': 'setForm',
        '$route': 'build',
        "json": {
            handler: "buildJsonTable",
            deep: true
        },
        "work_properties_value": {
            handler() {
                this.work_data_changed_count += 1
            },
            deep: true
        }
    },
    mounted() {
        this.build();
    },
    computed: {
        statusPlanned() {
            return this.infos.status === undefined || this.infos.status < 1
        },
        statusProgress() {
            return this.infos.status === 1
        },
        modalTitle() {
            return this.infos.status >= 1 ? '確認' : '修正'
        },
    },
    created() {
        // this.getWorkDetailsArrival();

    },
    methods: {
        async build() {
            this.getItemHeaders()
        },
        async getItemHeaders() {
            const response = await AwsApi.graphql({
                query: queries.queryHeadersWithTable,
                variables: {
                    tenantId: this.tenantId,
                    sourceTable: "ItemMaster"
                }
            }, this);
            if (response === undefined) {
                return
            }

            this.headers = response.data.queryItemDefines.items
        },
        cancelFun() {
            this.allData = []
            this.currentData = []
            this.page = 1
            this.paginate.total = 0
            this.paginate.current_page = 1
            this.paginate.per_page = this.count
            this.work_data_changed_count = -1
            this.hideModal()
        },
        addItem() {
            this.base_item.unique_id = Math.random().toString(36).substring(2);
            this.paginate.total += 1
            this.page = Math.ceil(this.paginate.total / this.paginate.per_page)
            this.allData.push({
                ...this.base_item
            })
            this.setData()
        },
        changeItemCode(value, row, target) {
            var jsonProperties = {}
            try {
                jsonProperties = JSON.parse(value.properties)
            } catch (error) {}
            var index = (this.page - 1) * this.count + row.index
            if (typeof value === 'string') {

                this.allData[index] = {
                    ...this.allData[index],
                    ...jsonProperties,
                }
                this.allData[index][target] = value
            } else {
                this.allData[index] = {
                    ...this.allData[index],
                    ...value,
                    ...jsonProperties,
                }
                this.allData[index][target] = value.id
            }

            if (this.allData[index].toCreate === undefined || !this.allData[index].toCreate) {
                this.allData[index].toUpdate = true
            }
            this.allData = [...this.allData]
            this.setData()
        },
        changeProperties(value, row, target) {
            this.allData[row.index][target] = value
            if (this.allData[row.index].toCreate === undefined || !this.allData[row.index].toCreate) {
                this.allData[row.index].toUpdate = true
            }

            this.allData = [...this.allData]
            this.setData()
        },
        removeItems(row) {
            // mark items for deletion
            this.allData_toDelete = []
            row.map(item => {
                if (item.unique_id !== undefined) {
                    this.allData = this.allData.filter(v => v.unique_id !== item.unique_id);
                } else {
                    this.allData_toDelete = this.allData_toDelete.concat(this.allData.filter(v => v['itemCode'] === item['itemCode']));
                    this.allData = this.allData.filter(v => v['itemCode'] !== item['itemCode']);
                }
            })
            this.setData()
            // this.$refs.table.$refs.btable.refresh();

            return;
        },
        async send(event) {
            event.preventDefault();
            AwsApi.checkSession(() => {
                this.working = true;

                const create = [];
                const update = [];
                const deleteItem = [];
                var hasCreate = false;
                var hasUpdate = false;
                const createDetails = [];
                const updateDetails = [];
                this.allData.forEach((item, index) => {
                    if (item['itemCode'] !== '' && item['expectAmount'] !== '') {
                        if (item.toCreate) {
                            hasCreate = true
                            createDetails.push({
                                itemCode: item['itemCode'],
                                expectAmount: item['expectAmount']
                            })
                        }
                        if (item.toUpdate) {
                            hasUpdate = true
                            updateDetails.push({
                                id: item['jobDetailId'],
                                itemCode: item['itemCode'],
                                expectAmount: item['expectAmount']
                            })
                        }
                    }
                })
                this.allData_toDelete.forEach((item, index) => {
                    deleteItem.push(API.graphql({
                        query: mutations.deleteJobDetail,
                        variables: {
                            id: item['jobDetailId']
                        }
                    }));

                })
                if (hasCreate) {
                    if (this.work_number !== null) {
                        createDetails.forEach((item, index) => {
                            var createJobDetail = {
                                itemCode: item.itemCode,
                                expectAmount: item.expectAmount,
                                jobId: this.work_number
                            }
                            create.push(API.graphql({
                                query: mutations.createJobDetail,
                                variables: {
                                    createJobDetail: createJobDetail
                                }
                            }));
                        })
                    } else {
                        var createJob = {
                            tenantId: this.tenantId,
                            menuId: this.url.menu_id,
                            warehouseId: this.url.warehouse_id,
                            details: createDetails,
                            properties: Object.keys(this.work_properties_value).length === 0 ? null : JSON.stringify(this.work_properties_value)
                        }
                        create.push(API.graphql({
                            query: mutations.createJob,
                            variables: {
                                createJob: createJob
                            }
                        }));
                        this.work_data_changed_count = -1
                    }

                }
                if (hasUpdate) {
                    updateDetails.forEach((item, index) => {
                        var updateJobDetail = {
                            itemCode: item.itemCode,
                            expectAmount: item.expectAmount,
                            jobId: this.work_number,
                            id: item.id
                        }
                        update.push(API.graphql({
                            query: mutations.updateJobDetail,
                            variables: {
                                updateJobDetail: updateJobDetail
                            }
                        }));
                    })
                }
                if (this.work_data_changed_count > 0 && this.work_number !== null) { //update job
                    const updateJob = {
                        id: this.work_number,
                        properties: Object.keys(this.work_properties_value).length === 0 ? null : JSON.stringify(this.work_properties_value)
                    }
                    update.push(API.graphql({
                        query: mutations.updateJob,
                        variables: {
                            updateJob: updateJob
                        }
                    }));
                }
                Promise.all([...update, ...create, ...deleteItem]).then(() => {
                    this.working = false;
                    this.hideModal();
                    this.$emit('refetchData');
                })
            }, this)
        },
        showConfirm() {
            this.msgModalTitle = "作業完了しますか？"
            this.$emit('changeMsgModal', true);
        },
        finishJob() {
            var updateJob = {
                tenantId: this.tenantId,
                id: this.work_number,
                menuId: this.url.menu_id,
                status: 2
            }
            AwsApi.graphql({
                query: mutations.updateJob,
                variables: {
                    updateJob: updateJob
                }
            }, this).then((response) => {
                if (response === undefined) {
                    return
                }
                this.hideModal();

                this.$emit('refetchData');
            }).catch(error => console.log(error))
        },
        deleteConfirm() {
            this.msgModalTitle = "作業を削除しますか？"
            this.$emit('changeMsgModal', true);
        },
        msgModalOK(value) {
            this.$emit('changeMsgModal', false);
            if (this.msgModalTitle === "作業を削除しますか？") {
                this.deleteWork()
            } else {
                this.finishJob()
            }
        },
        msgModalCancel(value) {
            this.$emit('changeMsgModal', false);
        },
        deleteWork() {
            AwsApi.graphql({
                query: mutations.deleteJob,
                variables: {
                    id: this.work_number
                }
            }, this).then((response) => {
                if (response === undefined) {
                    return
                }
                this.hideModal();
                this.$emit('refetchData');
            }).catch(error => error)

        },
        setForm() {
            this.dataChangeFlag += 1
            this.work_number = this.infos.work_number
            this.work_data_changed_count = -1
            this.work_properties_value = {}
            this.work_properties.forEach(i => {
                this.work_properties_value[i.name] = ""
            })

            if (this.infos.work_properties && this.infos.work_properties !== null) {
                this.work_properties_value = JSON.parse(this.infos.work_properties)
            }
            this.form = {
                warehouse_id: this.url.warehouse_id,
            }
            this.headers
            if (this.work_number !== null) {
                //TODO
                this.getWorkDetails()
            }
        },
        async getWorkDetails() {
            const myInit = {
                jobId: this.work_number,
                flag: true,
                limit: this.limit,
                offset: this.offset,
            };
            const response = await AwsApi.graphql({
                query: queries.getJobDetailList,
                variables: myInit
            }, this)
            if (response === undefined) {
                return
            }

            const convertData = response.data.getJobDetailList.map(item => {
                var itemMaster = null
                try {
                    itemMaster = JSON.parse(item.itemMaster)
                } catch (error) {}
                if (itemMaster !== null) {
                    var itemMasterProperties = {}
                    try {
                        itemMasterProperties = JSON.parse(itemMaster.properties)
                    } catch (error) {}
                    return {
                        jobDetailId: item.id,
                        ...item,
                        ...itemMaster,
                        ...itemMasterProperties
                    }
                } else {
                    return {
                        jobDetailId: item.id,
                        ...item
                    }
                }

            })

            this.allData = this.allData.concat(convertData);
            this.setData();
        },
        setData() {
            // this.sortData()
            this.currentData = this.allData.slice((this.page - 1) * this.count, (this.page) * this.count).map(item => {
                if (item.toCreate) {
                    return item
                } else {
                    return {
                        ...item,
                        toUpdate: true
                    }
                }
            })
            this.paginate = {
                total: this.allData.length,
                per_page: this.count,
                current_page: this.page
            };
        },
        updatePage(page) {
            this.page = page;
            if (this.page * this.count >= this.limit + this.offset) {
                this.offset += this.limit;
                this.getWorkDetails()
            } else {
                this.setData();
            }
        },

        buildJsonTable() {
            this.pending_new = this.json.map(item => {
                item.toCreate = item.item_code.trim().length === 0 || ('' + item.expected_number).trim().length === 0
                return item;
            })

            this.paginate.current_page = 1
            this.updatePage();

            // this.items_all = [...this.items_all, ...this.pushToItemsOptions]
            // this.paginate = {
            //     current_page: 1,
            //     per_page: 30,
            //     total: this.formItems.length
            // };
            // this.count = this.formItems.length
            // this.csv = true;
        },
        updateCsvPage(page) {
            this.paginate.current_page = page;
        },

        sortChange(value) {

            //check if value already in array
            const exist = this.sortBy.map(item => item.name).includes(value);
            // store the new value if not exist or change direction
            this.sortBy = exist ?
                this.sortBy
                .map(item => {
                    if (item.name === value) {
                        item.direction = item.direction === 'asc' ? 'desc' : 'delete'
                    }
                    return item
                })
                .filter(item => item.direction !== 'delete') : [...this.sortBy, {
                    name: value,
                    direction: 'asc'
                }];

            this.formItems = this.formItems.sort((a, b) => (a[this.sortBy[0].name] - b[this.sortBy[0].name]) * (this.sortBy[0].direction === 'asc' ? 1 : -1));

        },

        getDisplayNamesForForm() {
            // Api.get(`/api/menu/${this.url.menu_id}/settings`).then(response => {
            //     const labels = response.reduce((a,c) => {
            //         a[c.item_name] = c.display_name
            //         return a
            //     }, {})
            //     this.labels = labels
            // })
        },
        getWorkClassifications() {
            // Api
            //     .get(`/api/workclassification/select/${this.url.menu_id}`)
            //     .then(({work_classifications}) => this.work_classifications = work_classifications.map(({id, work_classification_name}) => ({
            //         value: id,
            //         text: work_classification_name
            //     })))
        },
        getItemsAll() {
            // return Api.get(`/api/item/all`).then(({data}) => this.items_all = [...data.map(item => ({
            //     text: item.item_code + (item.sub_code !== 0 ? '_'+item.sub_code:''),
            //     value: item.id
            // }))])
        },
        getItemsByWarehouse() {
            // return Api.get(`/api/item/warehouse/${this.url.warehouse_id}`).then(({data}) => this.items_all = [...data.map(item => ({
            //     text: item.item_code + (item.sub_code !== 0 ? '_'+item.sub_code:''),
            //     value: item.id
            // }))])
        },
        getTemporaryLocations() {
            // return Api
            //     .get(`/api/locations/warehouse/${this.url.warehouse_id}`)
            //     .then(({data}) => this.temporary_locations = data.map(location => ({
            //         text: location.location,
            //         value: location.id
            //     })))
        },
        async getDataFromAPI(work_number, warehouse, page, filterstr) {
            // let key = work_number + '.' + warehouse + '.' + page + '.' + filterstr;
            // let url = `/api/work_detail/work/${work_number}/warehouse/${warehouse}?page=${page}&filter=${filterstr}`
            // if (this.cache[key] !== undefined) return this.cache[key];
            // return this.cache[key] = await axios.get(url);
        },
        formatLocation(item_locations) {
            return item_locations.reduce((a, c) => {
                // get a and check if object already exists
                let exist = a.filter(item => {
                    return item.location === c.location.location
                });
                if (exist.length) {
                    return a.map(item => {
                        if (item.location === c.location.location && c.status === 1) {
                            item.amount += 1;
                            return item
                        }
                        return item
                    })
                } else {
                    if (c.status === 1) {
                        const toUpdate = {
                            'location': c.location.location,
                            'amount': 1
                        };
                        return [...a, toUpdate]
                    } else {
                        return a
                    }
                }
            }, []);
        },

        registerChangedItem(item) {
            this.pending_change[item.work_detail_id] = item
        },
        getPendingDeletedIds() {
            return Object.values(this.pending_change).filter(i => i.deleted).map(i => i.work_detail_id)
        },

        hideModal() {
            this.work_data_changed_count = -1
            this.work_properties_value = {}
            this.$emit('hideModalCreateJob')
        },
        clearErrors() {
            return this.errors = []
        },
        updateValue(value, name) {
            this.work_properties_value[name] = value
        }
    }
}
</script>

<style scoped>

</style>
